.container {
  margin-top: 48px;
}
.subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
p {
  opacity: 0.8;
  font-size: 13px;
  padding-left: 12px;
}

.cards {
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  padding: 12px 20px;
}
.card {
  display: flex;
  flex: 1;
  height: 24px;
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
}
.card .image {
  max-height: 24px;
  object-fit: contain;
}
