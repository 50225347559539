.container {
  background: #fff;
  text-align: center;
  padding-bottom: 32px;
  border-bottom: 1px solid #e0e0e0;
}
.heading {
  margin-bottom: 12px;
}
.image {
  width: 60px;
  height: 60px;
  margin-bottom: 18px;
}
