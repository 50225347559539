.planSelectionContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.planSelection {
  border-color: #e5e7eb;
  border-style: solid;
  margin-bottom: 5px;
  border-radius: 2px;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  color: black;
}

.planSelected {
  border-color: black;
}

.planSelectionHeader {
  display: flex;
  color: rgba(255 255 255);
  letter-spacing: -0.008em;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: black;
  align-items: center;
  gap: 0.25rem;
  justify-content: space-between;
}

.unselected {
  background-color: gray;
}

.selected {
  --tw-border-opacity: 1;
  border-color: rgb(48 48 49 / var(--tw-border-opacity));
}

.planSelectionHeaderLeft {
  gap: 0.25rem;
  align-items: center;
  display: flex;
}

.planSelectionHeaderRight {
  font-weight: 540;
}

.planSelectionHeaderPrice {
  gap: 0.25rem;
  flex-direction: column;
  display: flex;
}

.planSelectionPrice {
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.planSelectionHeaderPriceInfo {
  gap: 0.25rem;
  flex-direction: column;
  display: flex;
  align-items: start;
}

.planSelectionDuration {
  letter-spacing: -0.016rem;
  line-height: 1.375rem;
  font-size: 1.0625rem;
  font-weight: 540;
}

.planSelectionPerDay {
  align-items: flex-end;
  flex-direction: column;
  display: flex;
}

.planSelectionDollar {
  letter-spacing: -0.024rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-size: 1.5rem;
  gap: 0.25rem;
  display: flex;
  margin-bottom: -5px;
}

.planSelectionDayInfo {
  color: rgba(119, 119, 119);
  letter-spacing: -0.012rem;
  line-height: 1.25rem;
  font-size: 0.9375rem;
}

.planSelectionButton {
  gap: 0.75rem;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-bottom: 1rem;
}

.planSelectionGuarantee {
  text-align: center;
  line-height: 1rem;
  letter-spacing: -0.008em;
  font-size: 0.813rem;
  margin-top: -7px;
}

.strikethroughContainer {
  display: flex;
  align-items: center;
}

.gray {
  color: rgb(119, 119, 119);
}
