.winbackCheckoutContainer {
  margin: 0 auto;
  background: #fff;
  padding: 24px;
  position: relative;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.pricing h3 {
  color: black;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.headline {
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 41px;
  color: #000000;
  margin-bottom: 8px;
}
