.container {
  padding: 16px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;
  justify-content: space-between;
}
.content {
  width: 100%;

  text-align: center;
  text-wrap: wrap;
}

.buttonContainer {
  display: flex;
  width: 100%;
}
.icon {
  margin-top: 32px;
  margin-bottom: 24px;
  width: 60px;
  height: 60px;
}
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;

  /* Black */
  color: #000000;
  margin-bottom: 12px;
}
.description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140.76%;

  /* Black */
  color: #000000;
  margin-bottom: 24px;
}
