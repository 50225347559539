.youGetFeatures {
  margin-top: 48px;
  font-size: 17px;
  background: rgba(0, 0, 0, 0.05);
  padding: 10px 10px;
}
.youGetFeatures ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
