.rise-btn-base {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: black; /* or any other solid color */
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  padding: 0.5em 1em;
  text-align: center;
  border-radius: 15px;
  padding: 16px 32px;
  flex: 1;
}
.rise-btn-solid {
  height: 20px;
  background-color: black;
  color: white;
}
.rise-btn-solid:disabled {
  pointer-events: none;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
}

.rise-btn-clear {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
  color: rgb(97, 96, 98);
  font-size: 17px;
  display: flex;
  flex: 1;
  height: 56px;
  align-items: center;
  justify-content: center;
}
.rise-btn-compact {
  font-size: 12px;
  padding: 8px 16px;
}
/* Button.css */
.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.rise-btn-solid--inverted {
  background-color: white;
  color: black;
}

.rise-btn-solid--inverted:hover {
  background-color: #f0f0f0;
}
