.rowTitle {
  font-size: 16px;
  color: black;
  font-weight: bold;
  white-space: normal;
}
.title {
  font-size: 24px;
  color: black;
  font-weight: bold;
  white-space: normal;
}
