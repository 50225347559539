.title {
  font-size: 16px;
  color: var(--color-eggplant);
  text-align: left;
  padding-bottom: 24px;
}

.list {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 20px;
  gap: 16px;
  background: rgba(232, 222, 255, 0.4);
  border-radius: 16px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.icon {
  width: 16px;
  height: 16px;
}

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-eggplant);
}
