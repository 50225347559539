.header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 8px;
}

.headerLeft {
  margin-top: 10px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.headerCenter {
  display: flex;
  flex-direction: column;
  flex: 3;
  align-items: center;
  justify-content: center;
  position: relative;
}

.headerRight {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.category {
  font-family: sans-serif;
  font-size: 12px;
  background-color: rgba(142, 88, 255, 0.2);
  color: #392467;
  font-size: 12px;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 8px;
  text-transform: uppercase;
  pointer-events: none;
  transition: all 0.2s ease;
}

/*  */
.categoryHeader {
  height: 70px;
  overflow: hidden;
}
.ribbon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-0px);
  transition: transform 0.3s ease;
}

.sunLogo {
  width: 36px;
  transition: all 0.2s ease;
}

.basicHeader {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
