.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: 100%;
  white-space: pre-wrap;
}
.preHeaderText {
  color: #b85c00;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
  width: 100%;
}
.headerText {
  margin-top: 0px;
  font-size: 21px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: black;
  margin-top: 0;
  margin-bottom: 0px;
  width: 100%;
}

.bodyText {
  margin-top: 42px;
  margin-left: 16px;
  margin-right: 16px;
}
.heroImage {
  margin-top: 32px;
}
.secondaryImage {
  margin-top: 32px;
}
