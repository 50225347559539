.account-ready {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-top: 40px;
}

.account-ready .button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
.account-ready .store-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.account-ready .store-button {
  display: flex;

  justify-content: center;
  align-items: center;
  width: 100%;
  height: 52px;
  background-color: #000;
}
.account-ready .store-button img {
  height: 100%;
}
.account-ready .bullet-container {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 4px;
  gap: 15px;
}
.account-ready .numbered-bullets {
  margin-top: 30px;
  margin-bottom: 20px;
}
.account-ready .numbered-bullet {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}
.account-ready .bullet-line {
  width: 2px;
  position: absolute;
  top: 30px;
  bottom: 0;
  left: 12px;
  background-color: #000;
}
.account-ready .bullet-container span {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  display: block;
  flex: 1;
  word-break: break-word;
  max-width: 100%;
  white-space: normal;
}
