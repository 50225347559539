@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  margin: 0;
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 28rem;
  width: 100%;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h2 {
  color: black;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 12px;
  padding-bottom: 0;
}
h3 {
  color: black;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
.paragraph {
  color: black;
  font-size: 16px;
}
.centered {
  text-align: center;
}
