.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 120px;
}

.title {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
  color: #000000;
}

.sleepDebtGauge {
  margin-bottom: 20px;
  max-height: 226px;
}
.sleepDebtGauge img {
  max-height: 226px;
}
.sleepDebtScale {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}
.sleepDebtScale img {
  max-height: 70px;
}

.pods {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
  gap: 15px;
}
.infoIcon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.pod {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(142, 88, 255, 0.2);
  border-radius: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
}
.pod img {
  margin-bottom: 16px;
}
.podTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #4f4f4f;
  margin-bottom: 4px;
  text-wrap: balance;
  text-align: center;
}

.podValue {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: #382365;
  text-wrap: balance;
  text-align: center;
}

.goalPodContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.goalPodContainer .pod {
  align-items: flex-start;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 20px;
  padding-right: 20px;
}
.floatingButtonContainer {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;

  background-color: red;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;

  background: linear-gradient(to top, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
}
