.container {
  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 12px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
}
.container img {
  width: 24px;
  margin-right: 6px;
}
