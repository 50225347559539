.container {
  padding-bottom: 24px;
  border-bottom: 1px solid #e0e0e0;
}

.title {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-eggplant);
  text-align: left;
  padding-bottom: 12px;
  text-transform: uppercase;
}

.priceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.priceIcon {
  width: 32px;
  height: 32px;
}
.priceTitle {
  font-size: 21px;
  font-weight: 700;
  color: var(--color-purple1);
  text-align: left;
  margin-left: 8px;
}
.price {
  font-size: 34px;
  font-weight: 700;
  color: var(--color-purple1);
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  flex-grow: 1;
  justify-content: flex-end;
}
.currency {
  font-size: 18px;
  font-weight: 900;
  color: var(--color-purple1);
  text-align: left;
  margin-top: -6px;
}
