/**
NOTE: This is a temporary file to add RISE styles that are not yet in common.module.css
This is part of the migration to css modules instead of global css classes.
*/

.heading1 {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;

  /* Black */
  color: #000000;
}
.heading3 {
  font-size: 16px;
  font-weight: 500;
}
.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

.text-x-large {
  font-size: 40px;
}
.text-large {
  font-size: 24px;
}
.text-medium {
  font-size: 16px;
}

.leading-x-tight {
  line-height: 0.8;
}
.leading-tight {
  line-height: 1;
}
.leading-default {
  line-height: normal;
}
.leading-loose {
  line-height: 1.4;
}
.color-purple1 {
  color: #721cff;
}
.color-purple2 {
  color: #8e58ff;
}
.color-black {
  color: #000000;
}
.color-gray2 {
  color: #4f4f4f;
}
.color-gray3 {
  color: #828282;
}
.color-gray4 {
  color: #777777;
}

:root {
  --color-purple1: #721cff;
  --color-purple2: #8e58ff;
  --color-red1: #ea1744;
  --color-red2: #ff3963;
  --color-orange1: #fd8206;
  --color-orange2: #ff9900;
  --color-yellow1: #ffb800;
  --color-yellow2: #ffd600;
  --color-green1: #00b38b;
  --color-green2: #01edb8;
  --color-blue1: #1c63d6;
  --color-blue2: #0094ff;
  --color-pink1: #721cff;
  --color-pink2: #8e58ff;
  --color-panelGray1: #f92d8e;
  --color-panelGray2: #ff4a8b;
  --color-black: #000000;
  --color-gray1: #333333;
  --color-gray2: #4f4f4f;
  --color-gray3: #828282;
  --color-gray4: #bdbdbd;
  --color-gray5: #e0e0e0;
  --color-gray6: #f2f2f2;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-eggplant: #382365;
}
