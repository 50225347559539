.container {
  background: rgb(239, 68, 68);
  color: #fff;
  padding: 12px;
  border-radius: 2px;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.image {
  width: 24px;
  margin-right: 6px;
}
