.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: normal;
}

.stars {
  gap: 4px;
}

.cardTitle {
  padding-left: 12px;
}

.review {
  padding: 10px;
  font-size: 17px;
  color: black;
  text-align: center;
  white-space: normal;
}

.boldText {
  font-weight: bold;
}
