.container {
  margin: 0 auto;
  background: #fff;
  font-family: Arial, sans-serif;
  padding: 12px;
  position: relative;
  padding-bottom: 10rem;
}

.savedDiscountBannerContainer {
  position: fixed;
  top: -100px;
  left: 0;
  right: 0;
  transition: top 0.3s ease-in-out;
}

.savedDiscountBannerContainer.show {
  top: 0;
}

.savedDiscountBannerContainer.hide {
  top: -100px;
}
