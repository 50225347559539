.container {
  text-align: left;
  padding: 12px;
}
.title {
  margin-bottom: 10px;
}
.offerItems {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.offerItem {
  display: flex;
  align-items: flex-start;
  font-size: 17px;
}
.offerItem img {
  margin-right: 6px;
  margin-top: -3px;
}
