/**
NOTE: This is the common styles that are used in the app.
New common styles should be added here instead of adding them to riseStyle.css
which is a global css file and generally not recommended.
*/

.display1 {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  color: #000000;
}
.heading1 {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}
.heading3 {
  font-size: 16px;
  font-weight: 500;
}
.heading5 {
  font-size: 14px;
  font-weight: 500;
}
.heading6 {
  font-size: 14px;
  font-weight: 600;
}
.paragraph {
  font-size: 14px;
  line-height: 1.4;
}
.smallText {
  font-size: 12px;
}
.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}
.superBold {
  font-weight: 900;
}

.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

.textXLarge {
  font-size: 40px;
}
.textLarge {
  font-size: 24px;
}
.textMedium {
  font-size: 16px;
}

.leadingXTight {
  line-height: 0.8;
}
.leadingTight {
  line-height: 1;
}
.leadingDefault {
  line-height: normal;
}
.leadingLoose {
  line-height: 1.4;
}
.colorGray2 {
  color: #4f4f4f;
}
.colorGray3 {
  color: #828282;
}
.colorGray4 {
  color: #777777;
}
