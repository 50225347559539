.interstitial {
  height: 100%;
  width: 100%;
  text-wrap: wrap;
}

.interstitial .hero {
  padding-left: 24px;
  padding-right: 24px;
}
.interstitial h1 {
  font-size: 28px;
  font-weight: bold;
  color: black;
}
.interstitial p {
  font-size: 17px;
  color: rgb(97, 96, 98);
}
.centerText {
  text-align: center;
}
.boldText {
  font-weight: bold;
}
.card-container {
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 15px;
  background-color: white;
  padding-top: 17px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-color: rgb(228, 228, 231);
  box-shadow:
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
}
.card-title {
  font-size: 17px;
  color: black;
  font-weight: bold;
  padding-bottom: 10px;
}
