.gradientBorderBox {
  display: inline-block;
  padding: 2px;
  background: var(--color-gray5);
  border-radius: 12px;
}
.selected {
  background: linear-gradient(to right, var(--color-orange1), var(--color-purple1));
}
.container {
  border-radius: 11px;
  padding: 24px;
  padding-top: 20px;
  padding-bottom: 16px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
}
.pricingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--color-gray5);
  color: var(--color-black);
  padding-left: 16px;
  padding-right: 10px;
  height: 22px;
  border-bottom-left-radius: 16px;
  font-size: 11px;
  font-weight: 800;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected .badge {
  background: linear-gradient(to right, var(--color-orange1) -80%, var(--color-purple1) 100%);
  color: var(--color-white);
}
.price {
  color: var(--color-gray3);
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 600;
}
.priceIntro {
  color: var(--color-black);
  font-size: 14px;
  font-weight: 600;
}
.planContainer {
  flex: 1;
}
.planLabel {
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 4px;
}
.priceContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--color-gray3);
}
.perDayContainer {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 2px;
  margin-top: 8px;
}
.currency {
  font-size: 16px;
  color: var(--color-black);
  font-weight: 700;
  line-height: 1;
  margin-top: 3px;
}
.dollars {
  font-size: 40px;
  font-weight: 500;
  color: var(--color-black);
  line-height: 1;
}
.cents {
  font-size: 16px;
  color: var(--color-black);
  font-weight: 700;
  line-height: 1;
  margin-top: 3px;
}
.perDayLabel {
  margin-top: 2px;
  font-size: 12px;
  color: var(--color-gray3);
}
.offerDetails {
  padding-top: 16px;
  padding-right: 40px;
  font-size: 14px;
  color: var(--color-gray2);
  /* display: none; */
}
.selected .offerDetails {
  display: block;
}
