.button {
  background-color: black;
  border: none;
  color: white;
  font-weight: bold;
  padding: 15px 12px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  min-height: 48px;
  border-radius: 8px;
}

.checkoutContainer {
  flex-direction: column;
  display: flex;
}

.creditCard {
  padding: 1.5rem 1rem;
  border-radius: 2px;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
}

.cardDetails {
  letter-spacing: -0.012em;
  line-height: 1.25rem;
  font-size: 0.9375rem;
  margin-bottom: -0.75rem;
}

.creditCardTitle {
  letter-spacing: -0.016em;
  line-height: 1.375rem;
  font-size: 1.0625rem;
  font-weight: 560;
}

.paymentDetailsContainer {
  padding-top: 5rem;
}
.confirmPurchaseButton {
  gap: 0.5rem;
  align-items: center;
  display: flex;
}

.paymentDetails {
  letter-spacing: -0.016em;
  line-height: 1.375rem;
  text-align: center;
  color: black;
}

.encryption {
  gap: 0.75rem;
  flex-direction: column;
  display: flex;
  margin-top: 1.5rem;
}

.encryptionDetails {
  letter-spacing: -0.008em;
  line-height: 1rem;
  text-align: left;
  font-size: 0.813rem;
}

.paymentSecurityImageContainer {
  row-gap: 2rem;
  column-gap: 0.5rem;
  align-items: center;
  display: flex;
}

.paymentSecurityImage {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  height: 2rem;
  display: flex;
  position: relative;
  border: 0.5px solid #e5e7eb;
  object-fit: contain;
}

.paymentLogo {
  max-width: 100%;
  display: block;
  vertical-align: middle;
}

.cardOptions {
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
}

.paypal {
  margin-top: 0.7em;
  margin-bottom: 1.25em;
}
