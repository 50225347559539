.container {
  text-align: center;
}

.mapContainer {
  position: relative;
}
.peoplesHeads {
  position: absolute;
  top: 30px;
  left: 20px;
  right: 20px;
  bottom: 20px;
}
.image {
  width: 100%;
}
