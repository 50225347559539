.container {
  display: flex;
  width: 100%;
  height: 19px;
}

.segmentContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}
.firstSegment {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.lastSegment {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.segment {
  flex: 1;
  height: 3px;
  background-color: #e0e0e0;
  overflow: hidden;
}

.segmentHighlight {
  height: 4px;
  background-color: #721cff;
  transition: width 0.2s ease-in-out;
}

.segmentPip {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin-left: 5px;
  margin-right: 5px;
}
.segmentPipChecked {
  background-color: #721cff;
}
