.runwayerCheckout .image {
  width: 100%;
}
.runwayerCheckout h2 {
  color: black;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.runwayerCheckout h3 {
  color: black;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.runwayerCheckout .payment-method {
  background: #fff;
  text-align: center;
  padding: 20px;
  border-top: 1px solid #ccc;
}

.runwayerCheckout .payment-method img {
  width: 50px;
  margin: 0 10px;
}

.runwayerCheckout .payment-method {
  color: #fff;
  text-align: center;
  padding: 20px;
}

.runwayerCheckout h1,
.runwayerCheckout h2,
.runwayerCheckout h3 {
  color: #333;
}

.runwayerCheckout .black-button {
  background-color: rgb(48, 48, 49);
  border: none;
  color: white;
  font-weight: bold;
  padding: 15px 12px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
}

.plan-selection-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.plan-selection {
  border-color: #e5e7eb;
  border-style: solid;
  margin-bottom: 5px;
  border-radius: 2px;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  color: black;
}

.plan-selected {
  border-color: black;
}

.plan-selection-header {
  display: flex;
  color: rgba(255 255 255);
  letter-spacing: -0.008em;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: black;
  align-items: center;
  gap: 0.25rem;
  justify-content: space-between;
}

.unselected {
  background-color: gray;
}

.selected {
  --tw-border-opacity: 1;
  border-color: rgb(48 48 49 / var(--tw-border-opacity));
}

.plan-selection-header-left {
  gap: 0.25rem;
  align-items: center;
  display: flex;
}

.plan-selection-header-right {
  font-weight: 540;
}

.plan-selection-header-price {
  gap: 0.25rem;
  flex-direction: column;
  display: flex;
}

.plan-selection-price {
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.plan-selection-header-price-info {
  gap: 0.25rem;
  flex-direction: column;
  display: flex;
  align-items: start;
}

.plan-selection-duration {
  letter-spacing: -0.016rem;
  line-height: 1.375rem;
  font-size: 1.0625rem;
  font-weight: 540;
}

.plan-selection-per-day {
  align-items: flex-end;
  flex-direction: column;
  display: flex;
}

.plan-selection-dollar {
  letter-spacing: -0.024rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-size: 1.5rem;
  gap: 0.25rem;
  display: flex;
  margin-bottom: -5px;
}

.plan-selection-day-info {
  color: rgba(119, 119, 119);
  letter-spacing: -0.012rem;
  line-height: 1.25rem;
  font-size: 0.9375rem;
}

.plan-selection-button {
  gap: 0.75rem;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-bottom: 1rem;
}

.plan-selection-guarantee {
  text-align: center;
  line-height: 1rem;
  letter-spacing: -0.008em;
  font-size: 0.813rem;
  margin-top: -7px;
}

.strikethrough-container {
  display: flex;
  align-items: center;
}

.gray {
  color: rgb(119, 119, 119);
}
.runwayerCheckout-container {
  flex-direction: column;
  display: flex;
}

.paymentDetailsContainer {
  padding-top: 5rem;
}

.paymentDetails {
  letter-spacing: -0.016em;
  line-height: 1.375rem;
  text-align: center;
  color: black;
}

.checkoutContainer {
  flex-direction: column;
  display: flex;
}

.mt4 {
  margin-top: 1rem;
}

.creditCard {
  padding: 1.5rem 1rem;
  border-radius: 2px;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
}

.cardDetails {
  letter-spacing: -0.012em;
  line-height: 1.25rem;
  font-size: 0.9375rem;
  margin-bottom: -0.75rem;
}

.cardOptions {
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
}

.creditCardTitle {
  letter-spacing: -0.016em;
  line-height: 1.375rem;
  font-size: 1.0625rem;
  font-weight: 560;
}

.paymentLogo {
  max-width: 100%;
  display: block;
  vertical-align: middle;
}

.blackButton {
  background-color: rgb(48, 48, 49);
  border: none;
  color: white;
  font-weight: bold;
  padding: 15px 12px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
}

.confirmPurchaseButton {
  gap: 0.5rem;
  align-items: center;
  display: flex;
}

.encryption {
  gap: 0.75rem;
  flex-direction: column;
  display: flex;
  margin-top: 1.5rem;
}

.encryptionDetails {
  letter-spacing: -0.008em;
  line-height: 1rem;
  text-align: left;
  font-size: 0.813rem;
}

.paymentSecurityImageContainer {
  row-gap: 2rem;
  column-gap: 0.5rem;
  align-items: center;
  display: flex;
}

.paymentSecurityImage {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  height: 2rem;
  display: flex;
  position: relative;
  border: 0.5px solid #e5e7eb;
  object-fit: contain;
}

.paypal {
  margin-top: 0.7em;
  margin-bottom: 1.25em;
}

.account-creation-form {
  line-height: inherit;
  --font-onboard: '__Inter_4259e4', '__Inter_Fallback_4259e4';
  font-family:
    var(--font-onboard),
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  box-sizing: border-box;
  --border: 0 solid #e5e7eb;
  border-color: hsl(var(--border));
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.account-creation-text {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

.account-creation-text-title {
  text-align: center;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.25rem;
  letter-spacing: -0.024em;
}

.account-creation-text-subtitle {
  text-align: center;
  font-size: 1.0625rem;
  line-height: 1.375rem;
  letter-spacing: -0.016em;
}

.email-input-container {
  gap: 0.75rem;
  flex-direction: column;
  display: flex;
}

.email-input-container-2 {
  width: 100%;
}

.email-label {
  font-size: 14px;
  font-weight: bold;
}

.email-input {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
  margin: 0;
  margin-top: 12px;
  display: flex;
  height: 50px;
  width: 100%;
  border-radius: 2px;
  border-width: 1px;
  background-color: transparent;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.email-submit {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
  font-family: inherit;
  text-transform: none;
  cursor: pointer;
  user-select: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: rgb(48 48 49 / var(--tw-bg-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  text-align: left;
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: -0.016em;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
  animation-duration: 0.2s;
}

.users-container {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
}

.users {
  font-size: 0.9375rem;
  line-height: 1.25rem;
  letter-spacing: -0.012em;
}

.users-number {
  font-size: 0.9375rem;
  line-height: 1.25rem;
  letter-spacing: -0.012em;
  font-weight: bold;
}

.bullets {
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.bullet-point {
  line-height: inherit;
  display: flex;
  gap: 1rem;
}

.shrink-0 {
  flex-shrink: 0;
}

.bullet-point-text {
  font-size: 1.0625rem;
  line-height: 1.375rem;
  letter-spacing: -0.016em;
}

.image-container {
  line-height: inherit;
  position: relative;
  height: 3rem;
  width: 3rem;
  margin: 0;
}
.image-container.shift-left {
  margin-left: -20px;
}

.person {
  display: block;
  vertical-align: middle;
  max-width: 100%;
  border-radius: 9999px;
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  object-fit: contain;
  color: transparent;
}

.person-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
