.title {
  font-size: 21px;
  font-weight: bold;
  text-wrap: wrap;
  text-align: center;
  margin-bottom: 2px;
  white-space: normal;
}
.date {
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 6px;
  color: var(--color-purple1);
  white-space: normal;
}
.subtitle {
  font-size: 15px;
  color: var(--color-gray2);
  text-wrap: wrap;
  text-align: center;
  white-space: normal;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
}
.barContainer {
  display: flex;
  flex-direction: column;
  gap: 27px;
  padding-top: 14px;
}
