.container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-bottom: 32px;
  border-bottom: 1px solid #e0e0e0;
}

.planSelectionContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.planSelectionButton {
  margin-top: 10px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-eggplant);
  text-align: left;
  padding-bottom: 6px;
  text-transform: uppercase;
}
