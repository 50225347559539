.carouselContainer {
  overflow-x: scroll;
}
.carousel {
  width: fit-content;
  display: flex;

  gap: 8px;
}
.carouselItem {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  height: 80px;
  width: 160px;
}
.carousel img {
  height: 20px;
}
