.loading {
  position: fixed;
  inset: 0;
  z-index: 10050;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  text-align: center;
}
.loading .message {
  color: black;
  font-size: 16px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 12px;
  border-radius: 6px;
  text-align: center;
  margin-top: 16px;
}
.checkoutSpinner {
  border: 0 solid #e5e7eb;
  display: block;
  vertical-align: middle;
  height: 3rem;
  width: 3rem;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
