.barContainer {
  width: 100%;
  height: 3px;
  background-color: #e0e0e0;
  overflow: hidden;
  border-radius: 1.5px;
}

.bar {
  width: 0;
  height: 100%;
  background-color: #00b38b;
  animation: fillBar 0ms linear forwards;
}

@keyframes fillBar {
  to {
    width: 100%;
  }
}
