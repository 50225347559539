.discountButton {
  background-color: rgb(48, 48, 49);
  border: none;
  color: white;
  font-weight: bold;
  padding: 10px 12px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 15px;
  cursor: pointer;
}

.blackButton {
  background-color: rgb(48, 48, 49);
  border: none;
  color: white;
  font-weight: bold;
  padding: 15px 12px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
}
