.container {
}

.header {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 24px 36px;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 700;
  color: var(--color-eggplant);
  text-align: center;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline {
  margin-top: 24px;
  position: relative;
  height: 198px;
}
.timelineImage {
  position: absolute;
  top: 0;
  left: 0;
}

.timelineEntry {
  position: absolute;
  left: 24px;
  top: 0;
  right: 0;
}

.timelineEntryTitle {
  margin-top: -5px;
  font-size: 16px;
  font-weight: 700;
  color: var(--color-eggplant);
  margin-bottom: 4px;
}
.timelineEntryDescription {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: var(--color-gray1);
  text-align: left;
}
