.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.title {
  font-size: 24px;
}
.testimonial {
  border: 1px solid #ccc;
  padding: 12px;
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  font-size: 17px;
}

.testimonial cite {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  margin-top: -4px;
}
.stars {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
}
