.container {
  animation: fadeIn 2.5s ease-in-out;
  overflow: hidden;
  padding-bottom: 3px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes zoomInBounce {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  60% {
    transform: scale(1.1);
    opacity: 1;
  }
  75% {
    transform: scale(0.95);
  }
  85% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}
.title {
  font-size: 16px;
  font-weight: bold;
}
.delta {
  font-family: sans-serif;
  background-color: var(--color-purple1);
  padding: 4px 8px;
  border-radius: 12px;
  color: white;
  font-size: 12px;
  line-height: 12px;
  min-width: 35px;
  text-align: center;
  font-weight: bold;
  animation: zoomInBounce 0.5s linear;
}
.barContainer {
  position: relative;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bar {
  position: absolute;
  left: 0;

  width: 100%;
  transition: width 2.5s ease-in-out;
}
.barInner {
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 2px;
  height: 4px;
  background-color: var(--color-gray5);
}
.pipContainer {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pip {
  position: absolute;
  height: 8px;
  width: 9px;
  left: 0;
  transition:
    left 2.5s ease-in-out,
    opacity 0.5s ease-in-out;
}
.oldValuePip {
  height: 8px;
  width: 9px;
  margin-left: -5px;
  margin-top: 3px;
  background-color: white;
}
.oldValuePip::after {
  content: '';
  position: absolute;
  top: 0;
  left: 3px;
  width: 3px;
  background-color: var(--color-eggplant);
  height: 100%;
  border-radius: 1.5px;
}

.newValuePip {
  height: 16px;
  width: 9px;
  margin-left: -5px;
  margin-top: 2px;
  background-color: white;
  animation: fadeIn 0.5s linear;
}
.newValuePip::after {
  content: '';
  position: absolute;
  top: 0;
  left: 3px;
  width: 3px;
  background-color: var(--color-purple1);
  height: 100%;
  border-radius: 1.5px;
}

.criticalIcon {
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: 3px;
}
