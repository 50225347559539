.title {
  font-size: 21px;
  font-weight: 800;
  color: var(--color-black);
  text-align: left;
  white-space: normal;
}
.emailInputContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 32px;
}

.emailInput {
  /* flex: 1; */
  height: 45px;
  border: 1px solid var(--color-gray3);
  border-radius: 12px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-black);
}

.emailInput::placeholder {
  color: var(--color-gray3);
}

.emailInput:focus {
  outline: none;
}

.privacyPolicy {
  white-space: normal;
  font-family: sans-serif;
  margin-top: 32px;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  color: var(--color-gray2);
  text-align: left;
}
.privacyPolicy a {
  color: var(--color-gray2);
  text-decoration: none;
  font-weight: 600;
}
.emailInputError {
  border: 1px solid #b61135;
}
.errorMessage {
  color: #b61135;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  margin-top: 8px;
}
