.emailInputContainer {
  gap: 0.75rem;
  flex-direction: column;
  display: flex;
}

.emailInputContainer2 {
  width: 100%;
}

.emailLabel {
  font-size: 14px;
  font-weight: bold;
}

.emailInput {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
  margin: 0;
  margin-top: 12px;
  display: flex;
  height: 50px;
  width: 100%;
  border-radius: 2px;
  border-width: 1px;
  background-color: transparent;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.emailSubmit {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
  font-family: inherit;
  text-transform: none;
  cursor: pointer;
  user-select: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: rgb(48 48 49 / var(--tw-bg-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  text-align: left;
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: -0.016em;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
  animation-duration: 0.2s;
}

.usersContainer {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
}

.users {
  font-size: 0.9375rem;
  line-height: 1.25rem;
  letter-spacing: -0.012em;
}

.usersNumber {
  font-size: 0.9375rem;
  line-height: 1.25rem;
  letter-spacing: -0.012em;
  font-weight: bold;
}

.bullets {
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.bulletPoint {
  line-height: inherit;
  display: flex;
  gap: 1rem;
}

.accountCreationForm {
  line-height: inherit;
  --font-onboard: '__Inter_4259e4', '__Inter_Fallback_4259e4';
  font-family:
    var(--font-onboard),
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  box-sizing: border-box;
  --border: 0 solid #e5e7eb;
  border-color: hsl(var(--border));
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.person {
  display: block;
  vertical-align: middle;
  max-width: 100%;
  border-radius: 9999px;
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  object-fit: contain;
  color: transparent;
}

.personContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.imageContainer {
  line-height: inherit;
  position: relative;
  height: 3rem;
  width: 3rem;
  margin: 0;

  margin-left: -20px;
}
